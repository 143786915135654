import { useEffect, createRef, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import HorizontalScrollMenu from '../horizontal-scroll-menu';
import MediaPreview, {
  KNOWN_MEDIA_TYPES,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_360,
} from './media-preview';
import PreviewOverlay from './preview-overlay';

const PREFIX = 'MobileGallery';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  actionBar: `${PREFIX}-actionBar`,
  contentBody: `${PREFIX}-contentBody`,
  imageList: `${PREFIX}-imageList`,
  infoText: `${PREFIX}-infoText`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    fontFamily: 'Open Sans, sans-serif !important',
    position: 'fixed',
    top: 0,
    left: 0,
    minWidth: '100vw',
    maxWidth: '100vw',
    overflowX: 'hidden',
    minHeight: '100vh',
    maxHeight: '100vh',
    overFlowY: 'auto',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexFlow: 'column nowrap',
    zIndex: '50',
  },
  [`&.${classes.header}`]: {
    backgroundColor: '#ffffff',
    minWidth: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0 1.25rem',
  },
  [`& .${classes.actionBar}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '& .back-title': {
      display: 'flex',
      textTransform: 'capitalize',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      '& > .title': {
        fontSize: '16px',
        fontWeight: '700',
      },
    },
    '& .actions': {
      display: 'flex',
      flowFlow: 'row nowrap',
      alignItems: 'center',
    },
  },

  [`& .${classes.contentBody}`]: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#FCFCFF',
    borderTopLeftRadius: '0.8rem',
    borderTopRightRadius: '0.8rem',
    border: '1px solid #eeeeee',
    boxShadow: '2px 2px 12px #00000029',
    padding: '1rem 1.25rem 1rem 1.25rem',
    marginTop: '1rem',
    paddingBottom: '7em',
  },

  [`& .${classes.imageList}`]: {
    '&:not(:first-of-type)': {
      marginTop: '1rem',
    },
    '& > .heading': {
      margin: '0 0 0.75rem 0',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '0.95rem',
      fontWeight: '700',
      textTransform: 'capitalize',
    },
    '& > .thumbnails': {
      display: 'flex',
      flexFlow: 'row wrap',
      gap: '0.5rem',
      '& .corner-round': {
        borderRadius: '0.5rem',
      },
    },
  },

  [`& .${classes.infoText}`]: {
    color: '#888888',
    fontSize: '0.8rem',
  },
});

function Header(props) {
  const {
    categories,
    title,
    onBackClick,
    onCategoryClick,
    onListViewClick,
    onGridViewClick,
    listIconStyle,
    gridIconStyle,
  } = props;
  return (
    <div style={{ backgroundColor: '#ffffff', minWidth: '100%', display: 'flex', flexFlow: 'column nowrap', padding: '0 1.25rem',}} className={classes.header}>
      <div className={classes.actionBar}>
        <span className="back-title">
          <IconButton
            color="default"
            aria-label="back"
            component="span"
            onClick={onBackClick}
            edge="start">
            <ArrowBackIcon />
          </IconButton>
          <span className="title">{title}</span>
        </span>
        <span className="actions">
          <IconButton
            color="default"
            aria-label="list view"
            component="span"
            edge="end"
            style={listIconStyle}
            onClick={onListViewClick}>
            <ViewStreamIcon />
          </IconButton>
          <IconButton
            color="default"
            aria-label="gridview"
            component="span"
            edge="end"
            style={gridIconStyle}
            onClick={onGridViewClick}>
            <ViewModuleIcon />
          </IconButton>
        </span>
      </div>
      <div>
        <HorizontalScrollMenu
          menuitems={categories}
          onItemClick={onCategoryClick}
          itemStyles={{
            fontSize: '0.75rem',
            fontWeight: '700',
            marginRight: '11px',
            padding: '11px',
            fontFamily: 'Open Sans, sans-serif',
            color: '#171717',
            border: '1px solid #D2DEEB',
            borderRadius: '5px',
          }}
        />
      </div>
    </div>
  );
}
 
function ThumbnailList(props) {
  const { heading, componentRef, gridMode, entries, listId, project_name } = props;
  const items = entries ?? [];
  const onItemClick = props.onThumbnailClick ?? (() => {});

  const getMediaComponent = (item, index) => {
    const mediaProps = {
      className: !gridMode ? 'corner-round' : '',
    };
    switch (item.type) {
      case MEDIA_TYPE_IMAGE:
        // Add image specific props
        mediaProps.layout = 'responsive';
        mediaProps.width = '250';
        mediaProps.height = '150';
        break;
      case MEDIA_TYPE_VIDEO:
        // Add video specific props
        mediaProps.overlay = true;
        mediaProps.onOverlayClick = () => onItemClick(item, listId, index);
        break;
      case MEDIA_TYPE_360:
        // Add 360 video specific props
        mediaProps.overlay = true;
        mediaProps.onOverlayClick = () => onItemClick(item, listId, index);
    }
    return (
      <MediaPreview
        type={item.type}
        url={item.url}
        alt={`${project_name} ${item?.subCategory}`}
        onClick={() => onItemClick(item, listId, index)}
        {...mediaProps}
      />
    );
  };

  return (
    <div className={classes.imageList} ref={componentRef}>
      <h3 className="heading">{heading}</h3>
      <div className="thumbnails">
        {
          items.length > 0
            ? items.map((item, index) => (
                <div
                  key={index}
                  style={gridMode ? { width: '30%' } : { width: '100%' }}>
                  {getMediaComponent(item, index)}
                </div>
              ))
            : ''
          // <span className={classes.infoText}>No item found</span>
        }
      </div>
    </div>
  );
}

/*
GALLERY DATA FORMAT (for data prop)
{
  <category> : [
    {
      id: <categoryName-index>,
      type: 'image|video|360',
      url: <url>
    },
    {
      id: <categoryName-index>,
      type: 'image|video|360',
      url: <url>
    },
    ...`
  ]
}
*/
function MobileGallery(props) {
  const [viewAsGrid, setViewAsGrid] = useState(false);
  const [previewOverlayVisible, setPreviewOverlayVisible] = useState(false);
  const { title, data, onCloseClick, project_name } = props;
  const categories = Object.keys(data).map(
    catName => data[catName].length > 0 && catName
  );
  const categoriesRefs = Object.fromEntries(
    categories.map(category => [category, createRef()])
  );
  const overlayData = Object.keys(data)
    .map(category => data[category])
    .reduce((prev, curr) => prev.concat(curr), []);
  const [overlayFirstItemIndex, setOverlayFirstItemIndex] = useState(-1);
  const categoryClickHandler = useCallback((category, ref) => {
    const clickedCategoryRef =
      ref?.current ?? categoriesRefs[category]?.current;
    clickedCategoryRef?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const thumbnailClickHandler = useCallback(targetItem => {
    const targetItemIndex = overlayData.findIndex(
      item => item.id === targetItem.id
    );
    setOverlayFirstItemIndex(targetItemIndex);
    setPreviewOverlayVisible(true);
  }, []);

  useEffect(() => {
    const body = document.body;
    // Disable body scroll on mount
    body.style.overflowY = 'hidden';
    return () => {
      // Enable body scroll on unmount
      body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    if (props.selectedSection == 'Video') {
      categoryClickHandler('Video');
      thumbnailClickHandler(
        { id: 'video-1' },
        'Video',
        'https://d3of4uxmw6t77n.cloudfront.net/admin/public/images/video/property/1616845093.mp4'
      );
    } else if (props.selectedSection == 'Street') {
      categoryClickHandler('Street');
      thumbnailClickHandler(
        { id: 'street-1' },
        'Street',
        'https://d3of4uxmw6t77n.cloudfront.net/admin/public/images/video/property/1616845093.mp4'
      );
    } else if (props.selectedsection == 'Project Images') {
      categoryClickHandler('Project Images');
    }
  }, [props.selectedSection, categoryClickHandler, thumbnailClickHandler]);

  return (
    <Root>
      <div className={classes.root}>
        <Header
          title={title}
          onBackClick={onCloseClick}
          categories={categories}
          onCategoryClick={category =>
            categoryClickHandler(category, categoriesRefs[category])
          }
          onListViewClick={() => setViewAsGrid(false)}
          onGridViewClick={() => setViewAsGrid(true)}
          listIconStyle={viewAsGrid ? {} : { color: '#0056B2' }}
          gridIconStyle={viewAsGrid ? { color: '#0056B2' } : {}}
        />
        <div className={classes.contentBody}>
          {categories.map(category => (
            <ThumbnailList
              key={category}
              listId={category}
              onThumbnailClick={thumbnailClickHandler}
              gridMode={viewAsGrid}
              heading={category}
              project_name={project_name}
              componentRef={categoriesRefs[category]}
              entries={data[category]}
            />
          ))}
        </div>
        {previewOverlayVisible && (
          <PreviewOverlay
            data={overlayData}
            project_name={project_name}
            initialItemIndex={overlayFirstItemIndex}
            onClose={() => setPreviewOverlayVisible(false)}
          />
        )}
      </div>
    </Root>
  );
}

MobileGallery.propTypes = {
  title: PropTypes.string,
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: function (props, propName, componentName) {
          const propValue = props[propName]?.trim()?.toLowerCase();
          if (!KNOWN_MEDIA_TYPES.includes(propValue)) {
            return new Error(
              'Invalid value `' +
                propValue +
                '` in prop `' +
                propName +
                '` supplied to' +
                ' `' +
                componentName +
                '`. Must be one of (' +
                KNOWN_MEDIA_TYPES.join(',') +
                ') .'
            );
          }
        },
        url: PropTypes.string,
      })
    )
  ),
  onCloseClick: PropTypes.func,
};

export default MobileGallery;
